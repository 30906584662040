import React, {useState} from 'react';
import {Container, Nav, Navbar, OverlayTrigger, Tooltip} from "react-bootstrap";
import './BottomTabs.css'
import {Link, useLocation, useNavigate} from "react-router-dom";
import {IoChatbubbleEllipses, IoNewspaper,} from "react-icons/io5";
import JoinDialog from "../CustomModal/JoinDialog";
import DrawerMenu from "../DrawerMenu/DrawerMenu";
import {AiFillHome} from "react-icons/ai";
import {HiMenu} from "react-icons/hi";

const BottomTabs = () => {
    const navigate = useNavigate();
    let location = useLocation();
    const token = localStorage.getItem('web_access_token');
    const [open, setOpen] = useState(false);
    const [expend, setExpend] = useState(false);
    const defaultValues = ['/', '/ims-chats', '/news', '/social', '/rumors', '/videos', '/streaming'];

    const match = defaultValues.some(val => val === location.pathname);
    const isMatched = location.key === 'default' || match;

    const dialogOpen = () => {
        setOpen(true);
    }

    const dialogClose = () => {
        setOpen(false);
    }


    const redirectToPage = (link) => {
        if (token) {
            navigate(link);
        } else {
            dialogOpen();
        }
    }

    const openExpand = () => {
        setExpend(true);
    }

    const closeExpand = () => {
        setExpend(false);
    }

    return (
        <>
            {isMatched &&
            <Navbar variant="dark" className="bottom-nav bg-black" fixed="bottom" expand={false}>
                <Container>
                    <Nav className="bottom-nav-items d-flex justify-content-between flex-row w-100">
                        <OverlayTrigger overlay={<Tooltip id="tooltip-left">Home</Tooltip>}>
                            <Link to="/"
                                  className={`bottom-nav-items-icon flexing ${location.pathname === '/' && 'active-link'}`}>
                                <AiFillHome className="pt-0 fs-3"/>
                            </Link>
                        </OverlayTrigger>
                        <OverlayTrigger overlay={<Tooltip>Chats</Tooltip>}>
                            <div onClick={() => redirectToPage('/ims-chats')}
                                 className={`bottom-nav-items-icon flexing pointer ${location.pathname === '/ims-chats' && 'active-link'}`}>
                                <IoChatbubbleEllipses className="pt-0 fs-3"/>
                            </div>
                        </OverlayTrigger>
                        <OverlayTrigger overlay={<Tooltip>News</Tooltip>}>
                            <Link to="/news"
                                  className={`bottom-nav-items-icon flexing ${location.pathname === '/news' && 'active-link'}`}>
                                <IoNewspaper className="pt-0 fs-3"/>
                            </Link>
                        </OverlayTrigger>
                        <OverlayTrigger overlay={<Tooltip>Social</Tooltip>}>
                            <Link to="/social"
                                  className={`bottom-nav-items-icon flexing ${location.pathname === '/social' && 'active-link'}`}>
                                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 84.000000 72.000000"
                                     preserveAspectRatio="xMidYMid meet">
                                    <g transform="translate(0.000000,72.000000) scale(0.100000,-0.100000)">
                                        <path d="M72 700 c-66 -41 -72 -62 -72 -279 0 -184 1 -196 22 -227 39 -56 71
                                            -68 193 -73 l110 -3 37 -59 c49 -78 67 -78 116 0 l36 59 111 4 c99 3 116 6
                                            147 28 65 44 68 56 68 272 0 216 -6 237 -72 278 -31 19 -51 20 -348 20 -297 0
                                            -317 -1 -348 -20z m372 -132 c9 -12 16 -39 16 -60 l0 -38 60 0 c43 0 61 -4 68
                                            -16 13 -23 -15 -186 -35 -201 -12 -9 -59 -13 -149 -13 -161 0 -164 2 -164 99
                                            0 77 9 91 60 91 34 0 36 2 69 80 19 44 40 80 47 80 7 0 20 -10 28 -22z"/>
                                    </g>
                                </svg>
                            </Link>
                        </OverlayTrigger>
                        <OverlayTrigger overlay={<Tooltip>Menu</Tooltip>}>
                            <div onClick={openExpand}
                                 className={`bottom-nav-items-icon flexing pointer ${expend && 'active-link'}`}>
                                <HiMenu className="pt-0 fs-3 text-secondary"/>
                            </div>
                        </OverlayTrigger>
                        <DrawerMenu
                            show={expend}
                            closeExpand={closeExpand}
                        />
                    </Nav>
                </Container>
                {open &&
                <JoinDialog
                    show={open}
                    onHide={dialogClose}
                />
                }
            </Navbar>
            }
        </>
    );
}

export default BottomTabs;
