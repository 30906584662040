import React from 'react';
import Post from "../../components/Post/Post";
import {
    deleteVideosComment,
    getVideos,
    getVideosComments, shareVideos,
    updateVideosComment,
    updateVideosLike,
    videosComment
} from "../../http";

const Streaming = () => {
    return (
        <>

            <Post
                getPost={getVideos}
                getComments={getVideosComments}
                postComment={videosComment}
                updateLike={updateVideosLike}
                updatePostComment={updateVideosComment}
                deletePostComment={deleteVideosComment}
                sharePost={shareVideos}
            />
        </>
    );
}

export default Streaming;
