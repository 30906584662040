import React from 'react';
import './StreamingList.css';
import '../NewsList/NewsList.css';
import {getVideosList} from '../../http'
import PostLists from "../../components/PostLists/PostLIsts";
const StreamingList = () => {
    return (
        <div className="news">
            <div className="news-header flexing position-relative">
                <img src="/assets/images/placeholder/header_blank.png" alt="blank-placeholder"/>
                <h1 className="position-absolute">Streaming</h1>
            </div>
            <>
                <PostLists
                    getPostList={getVideosList}
                    url={'/streaming'}
                />
            </>
        </div>
    );
}

export default StreamingList;
