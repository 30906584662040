import React from 'react';
import '../Post/Post.css';
import {
    FacebookIcon,
    FacebookShareButton,
    TelegramIcon,
    TelegramShareButton, TwitterIcon, TwitterShareButton,
    WhatsappIcon, WhatsappShareButton
} from "react-share";
import {Button} from "react-bootstrap";
import {toast} from "react-hot-toast";
import './SharePost.css';

const SharePost = ({shareUrl}) => {
    const copyLink = () => {
        navigator.clipboard.writeText(shareUrl);
        toast.success('Link Copied!')
    }
    return (
        <div className="social">
            <FacebookShareButton url={shareUrl}>
                <FacebookIcon size={32} round/>
            </FacebookShareButton>
            <WhatsappShareButton url={shareUrl}>
                <WhatsappIcon size={32} round/>
            </WhatsappShareButton>
            <TwitterShareButton url={shareUrl}>
                <TwitterIcon size={32} round/>
            </TwitterShareButton>
            <TelegramShareButton url={shareUrl}>
                <TelegramIcon size={32} round/>
            </TelegramShareButton>
            <Button className="copyBtn bg-secondary text-white rounded-pill"
                    onClick={copyLink}>
                Copy Link
            </Button>
        </div>
    );
}

export default SharePost;
