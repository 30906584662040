import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import Load from "react-js-loader";
import ImageTag from "../shared/ImageTag";
import moment from "moment";
import {numFormatter} from "../../utils/utils";
import InfiniteScroll from "react-infinite-scroll-component";
import '../WallPostsFeed/WallPostsFeed.css'
import {IoChatboxOutline, IoHeartOutline, IoHeartSharp} from "react-icons/io5";
import {constants} from "../../config/constants";

const PostLists = (props) => {
    const {getPostList, url} = props;
    const history = useNavigate();
    const [postList, setPostList] = useState([])
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
            setLoading(true);
            if (count !== postList.length || postList.length === 0) {
                setTimeout(async () => {
                    await fetchPostList(page);
                }, 100);
            }
            setLoading(false);
    }, [page]);

    // fetch news list
    const fetchPostList = async () => {
        const {data} = await getPostList(page);
        if (data) {
            setPostList([...postList, ...data.data]);
            setCount(data.count);
        }
    };

    //redirect single post
    const singlePost = (id) => {
        history(`${url}/${id}`)
    }
    return (
        <div className="wallPostsFeed bg-white">
            {postList.length !== 0 ?
                <InfiniteScroll
                    dataLength={postList.length}
                    next={() => setPage(page => page + 1)}
                    hasMore={true}
                    loader={count === postList.length ? '' :
                        <Load
                            type="spinner-club"
                            bgColor={"#FFFF00"}
                            size={40}
                        />}>
                    <div style={{marginBottom: '45px'}}>
                        {postList && postList.map((posts, index) =>
                            <div className="wallPostsFeedInner mt-3 pointer" key={index}
                                 onClick={((e) => singlePost(posts._id))}>
                                <div className="wall-image">
                                    {posts.contentType === 'text' &&
                                    <ImageTag _image={`/assets/images/placeholder/${constants.INSTANCE_INFO.APP_PLACEHOLDER}`} alt={'WallImage'}/>
                                    }
                                    {posts.contentType === 'image' &&
                                    <ImageTag _image={posts.imageUrl} alt={'WallImage'}/>
                                    }
                                    {posts.contentType === 'video' &&
                                    <ImageTag _image={posts.imageUrl} alt={'WallImage'}/>
                                    }
                                </div>
                                <div className="wall-footer p-2 d-flex justify-content-between">
                                    <div className="d-flex flex-column gap-1">
                                        <div className="wall-left">
                                            <ImageTag
                                                _image={posts?.owner?.avatarUrl}
                                                alt={'profileImage'}
                                                isProfile={true}
                                            />
                                            <div>{posts?.owner?.displayName}</div>
                                        </div>
                                        <div className="wall-right">
                                            <h3 className='feed-title text-truncate d-inline-block text-start card-title h5 mb-0'>{posts.title}</h3>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column align-items-end">
                                        <div className="wall-left">
                                            <span>{moment(posts.created).fromNow()}</span>
                                        </div>
                                        <div className="wall-right">
                                            <div className="card-icon d-flex flex-column text-white">
                                                {posts.likedByMe ?
                                                    <span>
                                                        <IoHeartSharp className="pe-1 fs-4"/>
                                                        {numFormatter(posts.likeCount)}
                                                    </span> :
                                                  <span>
                                                      <IoHeartOutline className="pe-1 fs-4"/>
                                                      {numFormatter(posts.likeCount)}
                                                  </span>
                                                }
                                                <span>
                                                    <IoChatboxOutline className="pe-1 fs-4"/>
                                                    {numFormatter(posts.commentsCount)}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </InfiniteScroll> : 'No Posts'}
        </div>
    );
}

export default PostLists;
