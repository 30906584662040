import React from 'react';
import {Button, CloseButton, Modal} from "react-bootstrap";
import './CustomModal.css';
import {FaCheck, FaTimes} from "react-icons/fa";

const SubmitDialog = (props) => {

    const modalClose = () => {
        props.onHide();
    }

    return (
        <Modal
            show={props.show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="text-black"
        >
            <CloseButton className="close-btn position-absolute end-0 pt-3 pe-3 pointer" variant="white" onClick={modalClose}/>
            <Modal.Header className="position-relative m-auto">
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className='text-center'>{props.subTitle}</p>
            </Modal.Body>
            <Modal.Footer className="flexing gap-2">
                <Button className="btn ripple report-btn bg-yellow text-dark rounded-pill"
                        onClick={props.onHide}>
                    <FaTimes/>
                </Button>
                <Button className="btn ripple report-btn bg-primary rounded-pill" onClick={props.onSubmitBtn}>
                    <FaCheck/>
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default SubmitDialog;
