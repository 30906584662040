import {AWSBucket, constants} from "../config/constants";

export const uploadImageToAws = async (avatar) => {
    const rnd = (len, chars = constants.RANDOM_STRING) =>
        [...Array(len)].map(() => chars.charAt(Math.floor(Math.random() * chars.length))).join('');

    function getRandomFileName() {
        const name = rnd(20);
        return avatar.lastModified + name + avatar.size;
    }
    const params = {
        ACL: 'public-read',
        Body: avatar,
        Bucket: constants.S3AWS.S3_BUCKET,
        Key: getRandomFileName(),
    };
    return AWSBucket.upload(params).promise();
}

export const uploadAudioToAws = async (audio) => {
    const rnd = (len, chars = constants.RANDOM_STRING) =>
        [...Array(len)].map(() => chars.charAt(Math.floor(Math.random() * chars.length))).join('');

    function getRandomFileName() {
        const name = rnd(20);
        return name + audio.size + 'audio';
    }
    const params = {
        ACL: 'public-read',
        Body: audio,
        Bucket: constants.S3AWS.S3_BUCKET,
        Key: getRandomFileName(),
    };
    return AWSBucket.upload(params).promise();
}
