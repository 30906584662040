import React, {useState} from 'react';
import {Button, Form} from "react-bootstrap";
import {Formik} from "formik";
import * as yup from "yup";
import {FaCheck, FaPencilAlt, FaTimes, FaTrash} from "react-icons/fa";
import ImageTag from "../shared/ImageTag";
import moment from "moment";
import {toast} from "react-hot-toast";

const schema = yup.object().shape({
    comment: yup.string().required(),
});
const CommentList = (props) => {
    const {commentList, updatePostComment, updateCommentState, deletePostComment, setCommentCount, commentCount} = props;
    const [inputBox, setInputBox] = useState(false);
    const [postComment, setPostComment] = useState(null);
    const data = localStorage.getItem('web_user');
    const user = JSON.parse(data);

    const onEditComment = (e, comment) => {
        setInputBox(true);
        setPostComment(comment);
    }

    const handleComment = async (values) => {
        try {
            const {data} = await updatePostComment(postComment._id, {comment: values.comment});
            if (data) {
                const newArr = [...commentList];
                const index = newArr.findIndex(item => item._id === postComment._id);
                newArr.splice(index, 1, {...data});
                updateCommentState(newArr);
                updateCommentState(newArr);
                setInputBox(false);
                setPostComment(null);
            }
        } catch (err) {
            toast.error(err.response.data && err.response.data.errors[0].message);
        }
    }

    const onCancelEdit = () => {
        setInputBox(false);
        setPostComment(null);
    }

    const onDeleteComment = (e, id) => {
        deletePostComment(id);
        const newCommentArr = [...commentList];
        const newArr = newCommentArr.filter(el => el._id !== id);
        updateCommentState(newArr);
        setCommentCount(commentCount - 1);
    }

    return (
        <div className="post-comment-list d-flex flex-column gap-3 mb-5 mt-3">
            {commentList.slice()
                .sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime())
                .filter((obj) => !(obj && Object.keys(obj).length === 0)).map((comments, index) => (
                    <div key={comments._id}>
                        {inputBox && postComment._id === comments._id ?
                            <Formik
                                validationSchema={schema}
                                onSubmit={handleComment}
                                initialValues={{comment: postComment.comment}}
                            >
                                {({
                                      handleSubmit,
                                      handleChange,
                                      values,
                                      errors,
                                      isValid,
                                      dirty
                                  }) => (
                                    <Form noValidate onSubmit={handleSubmit}
                                          className="d-flex justify-content-start align-items-center">
                                        <div className="me-2 w-100">
                                            <Form.Group>
                                                <Form.Control
                                                    className="rounded-1"
                                                    id="comment"
                                                    name="comment"
                                                    type="text"
                                                    placeholder="Enter your comment here.."
                                                    value={values.comment}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.comment}
                                                    as="textarea"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.comment}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <div className="d-flex gap-1">
                                            <Button variant="primary" type="submit"
                                                    className="bg-primary rounded-pill"
                                                    disabled={!isValid || !dirty}
                                            >
                                                <FaCheck className="fs-4"/>
                                            </Button>
                                            <Button variant="primary" type="submit"
                                                    className="bg-light text-primary rounded-pill"
                                                    onClick={onCancelEdit}
                                            >
                                                <FaTimes className="fs-4"/>
                                            </Button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                            :
                            <div className="d-flex gap-3">
                                <div className="comment-list-avatar">
                                    <ImageTag _image={comments?.author?.avatarUrl} alt={'PostImage'} isProfile={true}/>
                                </div>
                                <div className="d-flex flex-column">
                                    <div className="text-black fw-bold">{comments.comment}</div>
                                    <div className="d-flex post-comment-author">
                                        <div className="me-2">{comments?.author?.displayName}</div>
                                        {'|'}
                                        <div className="ms-2">{moment(comments.created).format('MMM DD, YYYY')}</div>
                                    </div>
                                </div>
                                {comments?.authorId === user?._id &&
                                <div className="flexing gap-1">
                                    <Button variant="primary" type="submit"
                                            className="comment-actions-btn bg-light text-primary rounded-pill"
                                            onClick={(e) => onEditComment(e, comments)}
                                    >
                                        <FaPencilAlt/>
                                    </Button>
                                    <Button variant="primary" type="submit"
                                            className="comment-actions-btn bg-light text-primary rounded-pill"
                                            onClick={(e) => onDeleteComment(e, comments._id)}
                                    >
                                        <FaTrash/>
                                    </Button>
                                </div>
                                }
                            </div>
                        }
                    </div>
                ))
            }
        </div>
    );
}

export default CommentList;
