import React, { Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { useLoadingWithRefresh } from './hooks/useLoadingWithRefresh';
import { socketConnect, SocketContext } from './socket/SocketWrapper';
import Header from './components/Header/Header';
import Ticker from './components/Ticker/Ticker';
import BackButton from './components/BackButton/BackButton';
import { Toaster } from 'react-hot-toast';
import Loader from './components/Loader/Loader';
import BottomTabs from './components/BottomTabs/BottomTabs';
import firebase from 'firebase/compat/app';
import {getFriendRequest} from "./http";
import Streaming from "./pages/Streaming/Streaming";
import StreamingList from "./pages/StreamingList/StreamingList";

const Home = React.lazy(() => import('./pages/Home/Home'));
const NewsList = React.lazy(() => import('./pages/NewsList/NewsList'));
const News = React.lazy(() => import('./pages/News/News'));
const RumorsList = React.lazy(() => import('./pages/RumorsList/RumorsList'));
const Rumors = React.lazy(() => import('./pages/Rumors/Rumors'));
const Register = React.lazy(() => import('./pages/Auth/Register'));
const Login = React.lazy(() => import('./pages/Auth/Login'));
const ResetPasswordForm = React.lazy(() =>
    import('./pages/Auth/ResetPasswordForm')
);
const ResetPassword = React.lazy(() => import('./pages/Auth/ResetPassword'));
const Profile = React.lazy(() => import('./pages/Profile/Profile'));
const EditProfile = React.lazy(() => import('./pages/EditProfile/EditProfile'));
const VideosList = React.lazy(() => import('./pages/VideosList/VideosList'));
const Videos = React.lazy(() => import('./pages/Videos/Videos'));
const SocialList = React.lazy(() => import('./pages/SocialList/SocialList'));
const Social = React.lazy(() => import('./pages/Social/Social'));
const WallPost = React.lazy(() => import('./pages/WallPost/WallPost'));
const CreatePost = React.lazy(() => import('./pages/CreatePost/CreatePost'));
const Friends = React.lazy(() => import('./pages/Friends/Friends'));
const UserProfile = React.lazy(() => import('./pages/UserProfile/UserProfile'));
const ImsChats = React.lazy(() => import('./pages/ImsChats/ImsChats'));
const ImsGroups = React.lazy(() => import('./pages/ImsChats/ImsGroups'));
const PrivacyPolicy = React.lazy(() => import('./pages/Terms/PrivacyPolicy'));
const PageNotFound = React.lazy(() =>
    import('./pages/PageNotFound/PageNotFound')
);

const isBrowserSupported = firebase?.messaging?.isSupported();

let isIncognito = true;

// function isIncognito(){
//     return !!localStorage
// }

async function registerServiceWorker() {
    try {
        await navigator.serviceWorker.register('./firebase-messaging-sw.js');

        isIncognito = false;
    } catch {
        isIncognito = true;
    }
}

async function checkAndRegisterServices() {
    const registeredServices = await navigator.serviceWorker.getRegistrations();

    if (registeredServices.length === 0) {
        registerServiceWorker();
    }
}
const notify = localStorage.getItem('notify');

function App() {
    const [socket, setSocket] = useState(null);
    const [notifyCount, setNotifyCount] = useState(+notify);
    const token = localStorage.getItem('web_access_token');

    if ('serviceWorker' in navigator && isBrowserSupported && !isIncognito) {
        checkAndRegisterServices();
    }

    useEffect(() => {
        if (token && socket === null) updateSocket(token);
    }, [token]);

    // this function using for showing red dot
    useEffect(() => {
        if(socket) {
            const manegeNotification = (data) => {
                if (data.event === "friendsRequestReceived") {
                    getFriendRequest(1, 1).then(res => {
                        const _data = res.data.count;
                        if (_data !== 0) {
                            localStorage.setItem('notify', 1);
                            setNotifyCount(1)
                        } else {
                            localStorage.setItem('notify', 0);
                            setNotifyCount(0)
                        }
                    })
                }
            }
            socket.on('notification', manegeNotification);
        }
    }, [socket]);

    const updateSocket = tokens => {
        const socketVar = socketConnect(tokens);
        setSocket(socketVar);
    };

    const { loadings } = useLoadingWithRefresh();

    // Reset Password
    if (process.env.REACT_APP_PASSWORD_RESET_ONLY === 'true') {
        return loadings ? (
            <Loader />
        ) : (
            <>
                <div>
                    <Toaster position="top-center" />
                </div>
                <BrowserRouter>
                    <SocketContext.Provider value={socket}>
                        <Suspense fallback={<Loader />}>
                            <div className="sticky-top">
                                <Header/>
                                <Ticker />
                                <BackButton />
                            </div>
                            <Routes>
                                <Route
                                    path="/:id"
                                    element={<ResetPassword />}
                                />
                                <Route
                                    path="/"
                                    element={<ResetPasswordForm />}
                                />
                                <Route path="*" element={<PageNotFound />} />
                            </Routes>
                        </Suspense>
                    </SocketContext.Provider>
                </BrowserRouter>
            </>
        );
    }

    return (
        <>
            <div>
                <Toaster position="top-center" />
            </div>
            <BrowserRouter>
                <SocketContext.Provider value={socket}>
                    <div className="sticky-top">
                        <Header notifyCount={notifyCount}/>
                        <Ticker />
                        <BackButton />
                        <BottomTabs />
                    </div>
                    <Suspense fallback={<Loader />}>
                        <Routes>
                            <Route path="/" element={<Home />} exact />
                            <Route path="/news" element={<NewsList />} />
                            <Route path="/news/:id" element={<News />} />
                            <Route path="/videos" element={<VideosList />} />
                            <Route path="/videos/:id" element={<Videos />} />
                            <Route path="/streaming" element={<StreamingList />} />
                            <Route path="/streaming/:id" element={<Streaming />} />
                            <Route path="/social" element={<SocialList />} />
                            <Route path="/social/:id" element={<Social />} />
                            <Route path="/rumors" element={<RumorsList />} />
                            <Route path="/rumors/:id" element={<Rumors />} />
                            <Route path="/posts/:id" element={<WallPost />} />
                            <Route
                                path="/privacy-policy"
                                element={<PrivacyPolicy />}
                            />

                            {/*// Auth Routes*/}
                            <Route
                                path="/register"
                                element={
                                    <ProtectedRegisterRoute>
                                        <Register
                                            updateSocket={updateSocket}
                                            isIncognito={isIncognito}
                                        />
                                    </ProtectedRegisterRoute>
                                }
                            />
                            <Route
                                path="/login"
                                element={
                                    <ProtectedRoute>
                                        <Login updateSocket={updateSocket} />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/password-reset"
                                element={
                                    <ProtectedRoute>
                                        <ResetPasswordForm />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/profile"
                                element={
                                    <GuestRoute>
                                        <Profile updateSocket={updateSocket}/>
                                    </GuestRoute>
                                }
                            />
                            <Route
                                path="/profile/:id"
                                element={
                                    <GuestRoute>
                                        <EditProfile />
                                    </GuestRoute>
                                }
                            />
                            <Route
                                path="/create-post"
                                element={
                                    <GuestRoute>
                                        <CreatePost />
                                    </GuestRoute>
                                }
                            />
                            <Route
                                path="/friends"
                                element={
                                    <GuestRoute>
                                        <Friends />
                                    </GuestRoute>
                                }
                            />
                            <Route
                                path="/user-profile/:id"
                                element={
                                    <GuestRoute>
                                        <UserProfile setNotifyCount={setNotifyCount}/>
                                    </GuestRoute>
                                }
                            />
                            <Route
                                path="/ims-chats"
                                element={
                                    <GuestRoute>
                                        <ImsChats />
                                    </GuestRoute>
                                }
                            />
                            <Route
                                path="/ims-chats/groups"
                                element={
                                    <GuestRoute>
                                        <ImsGroups />
                                    </GuestRoute>
                                }
                            />
                            <Route path="*" element={<PageNotFound />} />
                        </Routes>
                    </Suspense>
                </SocketContext.Provider>
            </BrowserRouter>
        </>
    );
}

const ProtectedRoute = ({ children }) => {
    const token = localStorage.getItem('web_access_token');
    return token ? <Navigate to={'/profile'} /> : children;
};

const ProtectedRegisterRoute = ({ children }) => {
    const token = localStorage.getItem('web_access_token');
    return token ? <Navigate to={'/privacy-policy'} /> : children;
};

const GuestRoute = ({ children }) => {
    const token = localStorage.getItem('web_access_token');
    return !token ? <Navigate to={'/login'} /> : children;
};

export default App;
