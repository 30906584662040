import React, {useEffect, useRef, useState} from 'react';
import ImageTag from "../shared/ImageTag";
import './Post.css';
import {useNavigate, useParams} from "react-router-dom";
import ShowMoreText from "react-show-more-text";
import {generateString} from "../../utils/utils";
import CommentInput from "../Comment/CommentInput";
import CommentList from "../Comment/CommentList";
import JoinDialog from "../CustomModal/JoinDialog";
import 'react-responsive-modal/styles.css';
import {Lightbox} from "react-modal-image-responsive";
import {constants} from "../../config/constants";
import {toast} from "react-hot-toast";
import {isEmpty} from "lodash";
import {Button, Form, Spinner} from "react-bootstrap";
import {Formik} from "formik";
import * as yup from "yup";
import calculateAspectRatio from "calculate-aspect-ratio";
import {uploadImageToAws} from "../../functions/uploadImage";
import {updateWallPost} from "../../http";
import '../../pages/CreatePost/CreatePost.css';
import '../../pages/Auth/Auth.css';
import PostActionsPanel from "./PostActionsPanel";
import SubmitDialog from "../CustomModal/SubmitDialog";

const Schema = yup.object().shape({
    title: yup.string().required('You must enter your post title'),
});
const Post = (props) => {
    const {
        getPost,
        getComments,
        postComment,
        updateLike,
        updatePostComment,
        deletePostComment,
        sharePost,
        postType,
        deletePost
    } = props;
    const params = useParams();
    const [post, setPost] = useState({})
    const [previewImage, setPreviewImage] = useState('');
    const [open, setOpen] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [commentList, setCommentList] = useState([])
    const [loading, setLoading] = useState(false);
    const [shareOpen, setOpenShare] = useState(false);
    const [sharingUrl, setSharingUrl] = useState('');
    const [edited, setEdited] = useState(false);
    const [picture, setPicture] = useState(null);
    const [video, setVideo] = useState(null);
    const [imgSrc, setImgSrc] = useState(null);
    const [videoSrc, setVideoSrc] = useState(null);
    const [thumbnail, setThumbnail] = useState(null);
    const [thumbnailObj, setThumbnailObj] = useState(null);
    const [ratio, setRatio] = useState(0);
    const [page, setPage] = useState(1);
    const [commentCount, setCommentCount] = useState(0);
    const token = localStorage.getItem('web_access_token')
    const data = localStorage.getItem('web_user');
    const user = JSON.parse(data);
    const postId = params.id;
    const expand = false;
    const navigate = useNavigate();
    const videoElem = useRef();
    const imageElem = useRef();

    useEffect(() => {
        setLoading(true);
        fetchPostList(postId);
        setLoading(false);
    }, [postId]);

    useEffect(() => {
        if(page){
            fetchCommentList(postId);
        }
    }, [page]);

    useEffect(() => {
        if (edited) {
            videoElem.current.addEventListener('canplay', function (e) {
                setTimeout(() => {
                    captureThumbnail(videoElem.current.videoWidth, videoElem.current.videoHeight, videoElem.current)
                }, 1000);
            });
            if (thumbnail) {
                fetch(thumbnail)
                    .then((res) => res.blob())
                    .then((blob) => {
                        const NewFile = new File([blob], `video_thumbnail_${generateString(5)}.jpg`, {
                            type: "image/png"
                        });
                        setThumbnailObj(NewFile);
                    });
            }
        }
    }, [thumbnail, video]);

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            getAspect(imageElem.current?.naturalWidth, imageElem.current?.naturalHeight)
        }, 100);
        setLoading(false);
    }, [picture, thumbnail]);

    function getAspect(width, height) {
        const aspectRatio = calculateAspectRatio(width, height);
        const [x, y] = aspectRatio.split(':');
        const aspectRatioFloat = x / y;
        setRatio(aspectRatioFloat);
    }

    const captureThumbnail = (canvasWidth, canvasHeight, video) => {
        const canvas = document.createElement("canvas");
        canvas.width = canvasWidth;
        canvas.height = canvasHeight;
        canvas
            .getContext("2d")
            .drawImage(
                video,
                0,
                0,
            );
        setThumbnail(canvas.toDataURL('image/jpg'));
    };

    const handleImageChange = (e) => {
        setThumbnail('');
        setVideo('');
        setImgSrc(e.target.files[0]);
        setPicture(URL.createObjectURL(e.target.files[0]));
    }
    const handleVideoChange = (e) => {
        setPicture('');
        setVideoSrc(e.target.files[0]);
        setVideo(URL.createObjectURL(e.target.files[0]));
    }

    const fetchPostList = async (id) => {
        try {
            const {data} = await getPost(id);
            setPost(data)
        } catch (err) {
            toast.error('Post Not Found');
        }
    };
    const updateCommentState = (comment) => {
        setCommentList(comment);
    };

    const fetchCommentList = async (id) => {
        const {data} = await getComments(id, page);
        setCommentList([...commentList, ...data.data]);
        if(commentCount === 0){
            setCommentCount(data.count);
        }
    };

    const dialogOpen = () => {
        setOpen(true);
    }
    const dialogClose = () => {
        setOpen(false);
    }
    const handleComment = async (values) => {
        if (token) {
            const payload = {
                comment: values.comment,
                postId,
                wallId: post.wallId
            }
            await postComment(payload).then(res => {
                if (res.data) {
                    const newArr = [...commentList];
                    newArr.push(res?.data?.comment);
                    setCommentList(newArr);
                    setCommentCount(commentCount + 1);
                }
                window.scrollTo(0, 100);
            }).catch(err => {
                toast.error(err.response.data && err.response.data.errors[0].message);
            })
            values.comment = '';
        } else {
            dialogOpen();
        }
    }

    const submitLike = async () => {
        if (token) {
            if (!post.likedByMe) {
                const {data} = await updateLike(postId, {like: true})
                if (data) {
                    const newObj = {
                        ...post,
                        likedByMe: true,
                        likeCount: post.likeCount + 1
                    }
                    setPost(newObj)
                }
            } else {
                const {data} = await updateLike(postId, {like: false})
                if (data) {
                    const newObj = {
                        ...post,
                        likedByMe: false,
                        likeCount: post.likeCount - 1
                    }
                    setPost(newObj)
                }
            }
        } else {
            dialogOpen();
        }
    }

    const openImageModal = (url) => {
        setPreviewImage(url);
        setOpenModal(true);
    }

    const closeImageModal = () => {
        setOpenModal(false);
    }

    const postSharing = () => {
        if (token) {
            setOpenShare(true);
            sharePost(postId).then(res => {
                setSharingUrl(res.data.url);
            }).catch(err => {
                console.log(err);
            })
        } else {
            dialogOpen();
        }
    }

    const checkSharingMobile = () => {
        if (token) console.log('');
        else dialogOpen();
        }

    const closePostSharing = () => {
        setOpenShare(false);
    }

    const editSinglePost = () => {
        setEdited(true);
    }

    const backSinglePost = () => {
        setEdited(false);
        setPicture(null);
        setRatio(0);
        setThumbnail(null);
        setVideoSrc(null);
        setImgSrc(null);
        setVideo(null);
        setThumbnailObj(null);
    }

    // currant user delete post
    const deleteSinglePost = () => {
        deletePost(post._id).then((res) => {
            toast.success('Post deleted successfully');
            navigate('/');
        });
        setOpenDialog(false);
    }

    // currant user edit post
    const handleSubmit = async (values) => {
        setLoading(true);
        let imageUrl;
        let contentType;
        let videoUrl;
        let coverAspectRatio;
        try {
            if (imgSrc) {
                imageUrl = await uploadImageToAws(imgSrc);
                contentType = 'image';
                coverAspectRatio = ratio;
            } else if (videoSrc) {
                if (thumbnailObj) {
                    imageUrl = await uploadImageToAws(thumbnailObj);
                    coverAspectRatio = ratio;
                    if (imageUrl) {
                        videoUrl = await uploadImageToAws(videoSrc);
                        contentType = 'video';
                    }
                }
            } else {
                if (post.imageUrl && post.videoUrl) {
                    contentType = 'video';
                } else if (post.imageUrl) {
                    contentType = 'image';
                } else {
                    contentType = 'text';
                }
            }

            const payload = {
                title: values.title,
                bodyText: values.bodyText,
                imageUrl: imageUrl?.Location,
                videoUrl: videoUrl?.Location,
                coverAspectRatio,
                contentType: contentType,
            }
            if (payload.bodyText === (post.bodyText || '' || undefined)) delete payload.bodyText;
            if (payload.imageUrl === (post.imageUrl || '' || undefined)) delete payload.imageUrl;
            if (payload.videoUrl === (post.videoUrl || '' || undefined)) delete payload.videoUrl;

            const {data} = await updateWallPost(postId, payload);
            if (data) {
                setPost(data);
                setPicture('');
                setThumbnail('');
                setVideoSrc('');
                setImgSrc('');
                setRatio(0);
                values.title = '';
                values.bodyText = '';
                toast.success('Post successfully updated.');
                setLoading(false);
                setEdited(false);
            }

        } catch (err) {
            toast.error(err.response.data && err.response.data.errors[0].message);
            setLoading(false);
        }
    }

    const onDialogOpen = () => {
        setOpenDialog(true);
    }

    const onDialogClose = () => {
        setOpenDialog(false);
    }

    const onPagination = () => {
      setPage(page + 1);
    }

    return (
        <>
            <div className="post create-Wall-post-main">
                {isEmpty(post) ? '' :
                    <Formik
                        initialValues={{
                            title: post.title,
                            bodyText: post.bodyText,
                        }}
                        validationSchema={Schema}
                        onSubmit={handleSubmit}
                    >
                        {(formik) => {
                            const {
                                handleSubmit,
                                handleChange,
                                values,
                            } = formik;

                            const isDisabled = imgSrc === null && videoSrc === null && post.title === values.title && post.bodyText === values.bodyText;

                            return (
                                <Form noValidate onSubmit={handleSubmit}>
                                    {edited ?
                                        <div className="create-Wall-post-main-picture">
                                            <div className="create-post-file">
                                                <video className="w-100" ref={videoElem} id="video" src={video}
                                                       type="video/mp4" controls
                                                />
                                                {post.contentType === 'image' &&
                                                <img htmlFor="image-upload" ref={imageElem} className="w-100"
                                                     src={picture || thumbnail || post.imageUrl || `/assets/images/placeholder/${constants.INSTANCE_INFO.APP_PLACEHOLDER}`}
                                                     alt="profileImage"/>
                                                }
                                                {post.contentType === 'video' &&
                                                <img htmlFor="image-upload" ref={imageElem} className="w-100"
                                                     src={picture || thumbnail || post.imageUrl || `/assets/images/placeholder/${constants.INSTANCE_INFO.APP_PLACEHOLDER}`}
                                                     alt="profileImage"/>
                                                }
                                                {post.contentType === 'text' &&
                                                <img htmlFor="image-upload" ref={imageElem} className="w-100"
                                                     src={picture || thumbnail || `/assets/images/placeholder/${constants.INSTANCE_INFO.APP_PLACEHOLDER}`}
                                                     alt="profileImage"/>
                                                }
                                                <div
                                                    className="d-flex justify-content-center position-absolute align-items-center">
                                                    <label htmlFor="image-upload"
                                                           className="edit-image-btn flexing mb-3">
                                                        <i className="fas fa-image fa-2x"/>
                                                        <Form.Group className="mb-3 d-none">
                                                            <Form.Control
                                                                accept="image/*"
                                                                id="image-upload"
                                                                name="imageUrl"
                                                                type="file"
                                                                value={''}
                                                                onChange={handleImageChange}
                                                            />
                                                        </Form.Group>
                                                    </label>
                                                    <label htmlFor="video-upload"
                                                           className="edit-image-btn right flexing mb-3">
                                                        <i className="fas fa-video fa-2x"/>
                                                        <Form.Group className="mb-3 d-none">
                                                            <Form.Control
                                                                accept="video/*"
                                                                id="video-upload"
                                                                name="videoUrl"
                                                                type="file"
                                                                value={''}
                                                                onChange={handleVideoChange}
                                                            />
                                                        </Form.Group>
                                                    </label>
                                                </div>
                                            </div>
                                        </div> :
                                        <div className="post-image">
                                            {post.contentType === 'image' &&
                                            <img src={post.imageUrl} alt={'PostImage'}
                                                 onClick={() => openImageModal(post.imageUrl)}/>
                                            }
                                            {post.contentType === 'text' &&
                                            <ImageTag
                                                _image={`/assets/images/placeholder/${constants.INSTANCE_INFO.APP_PLACEHOLDER}`}
                                                alt={'PostImage'}/>
                                            }
                                            {post.contentType === 'video' &&
                                            <div className="images">
                                                <video className="w-100" src={post.videoUrl} type="video/mp4" controls
                                                       controlsList="nodownload"/>
                                            </div>
                                            }
                                        </div>
                                    }
                                    <PostActionsPanel
                                        post={post}
                                        submitLike={submitLike}
                                        shareOpen={shareOpen}
                                        sharingUrl={sharingUrl}
                                        commentList={commentList}
                                        postSharing={postSharing}
                                        closePostSharing={closePostSharing}
                                        token={token}
                                        checkSharingMobile={checkSharingMobile}
                                    />
                                    <div className="p-3">
                                        <div className="post-title">
                                            {edited ?
                                                <Form.Group>
                                                    <Form.Control
                                                        className="rounded-0 post-form-title remove-border"
                                                        id="title"
                                                        name="title"
                                                        type="text"
                                                        placeholder="Enter your post's title *"
                                                        value={values.title || post.title}
                                                        onChange={handleChange}
                                                    />
                                                </Form.Group> :
                                                <h3 className="fw-bold">
                                                    {post.title}
                                                </h3>
                                            }
                                            {edited ?
                                                <Form.Group>
                                                    <Form.Control
                                                        className="rounded-0 remove-border"
                                                        id="bodyText"
                                                        name="bodyText"
                                                        type="text"
                                                        as={"textarea"}
                                                        placeholder="Start writing your post..."
                                                        value={values.bodyText || post.bodyText}
                                                        onChange={handleChange}
                                                    />
                                                </Form.Group> :
                                                <ShowMoreText
                                                    className="post-expend"
                                                    more={"READ MORE"}
                                                    less={"READ LESS"}
                                                    expanded={expand}
                                                >
                                                    {post.bodyText}
                                                </ShowMoreText>
                                            }
                                        </div>
                                        {post.wallId === user?._id && postType === "Wall" &&
                                        <div className="fw-bold fs-8 d-flex align-items-center gap-3">
                                            {!edited ?
                                                <>
                                                    <div className="pointer bg-white text-green-primary p-0"
                                                         onClick={editSinglePost} type="button">Edit
                                                    </div>
                                                    <div className="pointer bg-white text-green-primary p-0" type="button"
                                                         onClick={onDialogOpen}>Delete
                                                    </div>
                                                </> :
                                                <>
                                                    <Button
                                                        className="pointer bg-green-primary rounded-pill px-3"
                                                        type="submit"
                                                        disabled={isDisabled}>
                                                        {loading ? <Spinner animation="border" size="sm"
                                                                            variant="light"/> : 'Save'}
                                                    </Button>
                                                    <div className="pointer bg-white text-green-primary p-0" type="button"
                                                         onClick={backSinglePost}>
                                                        Back
                                                    </div>
                                                </>
                                            }
                                        </div>
                                        }

                                          <CommentList
                                              commentCount={commentCount}
                                              setCommentCount={setCommentCount}
                                              updatePostComment={updatePostComment}
                                              commentList={commentList}
                                              expand={expand}
                                              updateCommentState={updateCommentState}
                                              deletePostComment={deletePostComment}
                                          />
                                        {commentCount > commentList.length &&
                                            <div className="d-flex mb-5 justify-content-center w-100">
                                                <Button
                                                    onClick={onPagination}
                                                    className="bg-white text-primary border rounded-pill btn">
                                                    Load More
                                                </Button>
                                            </div>
                                        }
                                        {open &&
                                        <JoinDialog
                                            show={open}
                                            onHide={dialogClose}
                                        />
                                        }
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                }
                <div className="post-comment-input position-fixed bottom-0 p-3">
                    <CommentInput
                        handleComment={handleComment}
                        open={open}
                        dialogClose={dialogClose}
                    />
                </div>
            </div>
            {openModal &&
            <Lightbox
                medium={previewImage}
                large={previewImage}
                onClose={closeImageModal}
                hideDownload={true}
            />
            }
            {openDialog &&
            <SubmitDialog
                title={'Remove Post'}
                subTitle={'Are you sure you want to remove post?'}
                show={openDialog}
                onHide={onDialogClose}
                onSubmitBtn={deleteSinglePost}
            />
            }
        </>
    );
}

export default Post;
