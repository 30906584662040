import React from 'react';
import {Button, Form} from "react-bootstrap";
import {Formik} from "formik";
import * as yup from "yup";
import JoinDialog from "../CustomModal/JoinDialog";
import {MdSend} from "react-icons/md";

const schema = yup.object().shape({
    comment: yup.string().required(),
});
const CommentInput = ({handleComment, open, dialogClose}) => {
    return (
        <Formik
            validationSchema={schema}
            onSubmit={handleComment}
            initialValues={{comment: ''}}
        >
            {({
                  handleSubmit,
                  handleChange,
                  values,
                  errors,
                  isValid,
                  dirty
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group className="position-relative">
                        <Form.Control
                            id="comment"
                            name="comment"
                            type="text"
                            placeholder="Enter your comment here.."
                            value={values.comment}
                            onChange={handleChange}
                        />
                        <Button variant="primary" type="submit"
                                className="comment-btn bg-primary rounded-pill rounded-start position-absolute"
                                disabled={!isValid || !dirty}
                        >
                            <MdSend className="fs-4"/>
                        </Button>
                    </Form.Group>
                    {open &&
                    <JoinDialog
                        show={open}
                        onHide={dialogClose}
                    />
                    }
                </Form>
            )}
        </Formik>
    );
}

export default CommentInput;
