import React, {useEffect, useState} from 'react';
import {numFormatter} from "../../utils/utils";
import {FaThumbsUp} from "react-icons/fa";
import {HiOutlineShare, HiOutlineX} from "react-icons/hi";
import SharePost from "../SharePost/SharePost";
import {BiCommentDots} from "react-icons/bi";
import ImageTag from "../shared/ImageTag";
import {RWebShare} from "react-web-share";


const PostActionsPanel = (props) => {
    const {post, submitLike, shareOpen, sharingUrl, postSharing, closePostSharing, commentList, token, checkSharingMobile } = props;
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const detectDeviceType = () =>
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
                ? setIsMobile(true)
                : setIsMobile(false);
        detectDeviceType();
    }, [isMobile])

    return(
        <div className="post-actions d-flex justify-content-between align-items-center">
            <div className="post-icon d-flex justify-content-between align-items-center w-100 px-3">
                <span onClick={submitLike} className="pointer flexing">
                    {post.likedByMe === true ?
                        <FaThumbsUp className="me-2 text-yellow fs-4"/> :
                        <FaThumbsUp className="me-2 fs-4"/>
                    }
                    {numFormatter(post.likeCount)}
                </span>
                {!isMobile &&
                <div className="social-share">
                    <span className="flexing shareBtn">
                        {!shareOpen && <HiOutlineShare className="me-2 fs-4" onClick={postSharing}/>}
                        {shareOpen && <HiOutlineX className="me-2 fs-4" onClick={closePostSharing}/>}
                        {shareOpen && <SharePost shareUrl={sharingUrl}/>}
                        {numFormatter(post.shareCount)}
                    </span>
                </div>
                }
                {isMobile && token ?
                    <RWebShare data={{url: sharingUrl}} onClick={() => console.info("")}>
                        <span className="flexing">
                            <HiOutlineShare className="me-2 fs-4"/>
                            {numFormatter(post.shareCount)}
                        </span>
                    </RWebShare> :
                        <>
                            {isMobile &&
                            <span className="flexing" onClick={() => checkSharingMobile()}>
                                <HiOutlineShare className="me-2 fs-4"/>
                                {numFormatter(post.shareCount)}
                            </span>
                            }
                        </>
                }
                <span className="flexing">
                    <BiCommentDots className="me-2 fs-4"/>
                    {numFormatter(commentList?.length || post.commentsCount)}
                </span>
            </div>
            <div className="hr border border-light mx-3 opacity-75"/>
            <div className="px-3">
                <ImageTag
                    _image="/assets/images/logos/logo_fanschat.png"
                    isProfile={true}
                    _class={"post_fc_logo"}
                />
            </div>
        </div>
    )
}
export default PostActionsPanel;
